<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-3">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6"
            @click="stampaTessereArray()"
          >
            <i class="bi bi-printer fs-6 text-gray-700 me-1"></i>stampa tessere
            (elenco visualizzato)
          </button>
        </div>
      </div>
      <div class="col-3 text-center">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_esporta_tesserati"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
            tesserati filtrata
          </button>
        </div>
      </div>
      <ModalEsportaTesserati />
      <div class="col-3 text-center">
        <div class="btn btn-light text-gray-700 fs-6">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioni"
            :checked="tutteStagioniTesserati"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          tesserati di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        tesserati_societa_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="tesserati_societa_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsTesseratoSoc"
        @current-change="setCurrentPageTesseratoSoc"
      >
        <template v-slot:cell-stagione="{ row: data }">{{
          data.stagione
        }}</template>
        <template v-slot:cell-societa="{ row: data }"
          ><div class="tab-long">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id_societa}`"
            >
              {{ data.societa }}
            </router-link>
          </div>
        </template>
        <template v-slot:cell-nominativo_completo="{ row: data }"
          ><div class="tab-long">
            <router-link
              :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
            >
              {{ data.nominativo_completo }}</router-link
            >
          </div></template
        >
        <template v-slot:cell-cod_tessera="{ row: data }">{{
          data.cod_tessera
        }}</template>
        <!--  <template v-slot:cell-tipo_tessera="{ row: data }">{{
          data.tipo_tessera
        }}</template> -->
        <template v-slot:cell-categoria="{ row: data }"
          ><div class="tab-long">{{ data.categoria }}</div></template
        >
        <template v-slot:cell-classifica_attuale="{ row: data }"
          ><div class="text-center">
            {{ data.classifica_attuale }}
            <div
              v-if="data.classifica_attuale != '-'"
              class="btn-group"
              @click="
                getRankList(data.id_persona, data.id_disciplina);
                selectedPersona = data.nominativo_completo;
              "
            >
              <button class="btn btn-sm p-1">
                <a
                  href="#"
                  class="navi-link"
                  data-bs-toggle="modal"
                  data-bs-target="#dettaglio_classifica"
                >
                  <i class="bi bi-info-circle text-dark fs-6 p-1"></i>
                </a>
              </button>
            </div></div
        ></template>
        <template v-slot:cell-data_certificato="{ row: data }"
          ><div class="text-center">{{ data.data_certificato }}</div></template
        >
        <template v-slot:cell-data_emissione="{ row: data }"
          ><div class="text-center">{{ data.data_emissione }}</div></template
        >
        <template v-slot:cell-data_scadenza="{ row: data }"
          ><div class="text-center">{{ data.data_scadenza }}</div></template
        >
        <template v-slot:cell-data_stampa="{ row: data }"
          ><div class="text-center">{{ data.data_stampa }}</div></template
        >
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <router-link
              :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
              class="btn btn-sm dropdown p-1"
            >
              <i class="bi bi-eye text-dark fs-5"></i>
            </router-link>
            <button
              v-if="!readOnly || (readOnly && data.data_emissione)"
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="stagione_selezionata = data.stagione"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <router-link
                :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
                v-if="!readOnly"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-pen text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Modifica tesserato
                      </span>
                    </div>
                  </div>
                </li></router-link
              >
              <a
                href="#"
                v-if="data.data_emissione"
                @click="
                  stampaTessera(
                    data.id,
                    data.id_societa,
                    data.id_persona,
                    data.cod_tessera
                  )
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-printer text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Stampa Tessera </span>
                    </div>
                  </div>
                </li></a
              >
              <router-link
                :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id_societa}`"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-briefcase text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Visualizza Società
                      </span>
                    </div>
                  </div>
                </li></router-link
              >
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#dettaglio_attivita_sportiva"
                @click="
                  getAttivitaSportiva(data.cod_tessera);
                  selectedPersona = data.nominativo_completo;
                "
                v-if="
                  data.tipo_tessera == 'Atleta' &&
                  isEnabled('fnTesseratiSocietaVerificaAttivitaSocieta')
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="">
                        <img
                          src="/media/fit-media/297262.svg"
                          width="17"
                          height="17"
                          class="mb-1"
                      /></i>
                      <span class="text-dark ps-1 fs-7">
                        Attività sportiva
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="isEnabled('fnSocietaTesseratiQrTesserato')"
                data-bs-toggle="modal"
                data-bs-target="#modal_visualizza_tessera"
                @click="
                  selectedTessera = data.cod_tessera;
                  selectedCrypt = data.crypt;
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-qr-code text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Lettura QR Code </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- :corsi_aggiornamento_snm_list="corsi_aggiornamento_snm_list -->
  <DettaglioClassifica
    :nominativo_persona="selectedPersona"
    :rank_list="rank_list"
    :loadingDetail="loadingDetail"
  />
  <DettaglioAttivitaSportiva
    :nominativo_persona="selectedPersona"
    :attivita_sportiva_list="attivita_sportiva_list"
    :loadingDetail="loadingDetail"
    :error="error"
  />
  <ModalTesseraQr
    :cod_tessera="selectedTessera"
    :crypt="selectedCrypt"
    @resetTessera="
      selectedTessera = null;
      selectedCrypt = null;
    "
  />
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import ModalEsportaTesserati from "./ModalEsportaTesserati.vue";
import DettaglioClassifica from "@/components/components-fit/ranking/ModalDettaglioClassifica.vue";
import DettaglioAttivitaSportiva from "@/components/components-fit/tesserati/tesserati-societa/ModalAttivitaSportiva.vue";
import ModalTesseraQr from "@/components/components-fit/tesserati/tesserati-societa/ModalTesseraQr.vue";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {
  stampaTessereTutteBW,
  preparaStampa,
} from "@/requests/tesseratoUnaSocRequests";
import { viewRankList } from "@/requests/ranking";
import { viewAttivitaSportiva } from "@/requests/personaRequests";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "TableTesseratiSocieta",
  components: {
    Datatable,
    ModalEsportaTesserati,
    Loading,
    DettaglioClassifica,
    DettaglioAttivitaSportiva,
    ModalTesseraQr,
  },
  emits: ["getTesseratiSocietaList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsTesseratoSoc = (e) => {
      store.commit("setFetchRowsTesseratoSoc", e);
      emit("getTesseratiSocietaList");
    };
    const setCurrentPageTesseratoSoc = (page) => {
      store.commit("setCurrentPageTesseratoSoc", page);
      emit("getTesseratiSocietaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnTesseratoSoc", columnName);
      store.commit("setSortOrderTesseratoSoc", order);
      emit("getTesseratiSocietaList");
    };

    const isLoading = ref(false);

    const stampaTessera = async (
      id_tesseramento,
      id_societa,
      id_persona,
      cod_tessera
    ) => {
      isLoading.value = true;
      const arr_tesseramento = [
        {
          id: id_tesseramento,
          id_societa,
          id_persona,
          cod_tessera,
        },
      ];
      await preparaStampa(arr_tesseramento, stagione.value).then((res) => {
        if (res.status == 200) {
          const item_id = res.data.item_id;
          isLoading.value = false;
          stampaTessereTutteBW(item_id);
        } else {
          isLoading.value = false;
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
      // then((res) => {
      //   isLoading.value = false;
      //   if (res.status == 200) {
      //
      //   } else {
      //     alertFailed(
      //       res.data.message
      //         ? res.data.message
      //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
      //     );
      //   }
      // });
    };

    const tesserati_societa_list = computed(() =>
      store.getters.getStateFromName("resultstesserati_societa_list")
    );

    const tessereArray = ref([]);
    const item_id = ref(null);

    const stampaTessereArray = async () => {
      tessereArray.value = [];
      item_id.value = null;
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Procedendo verranno stampate tutte le tessere visualizzate nella lista (escluse le tessere che hanno subito un upgrade).",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Stampa",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          tesserati_societa_list.value.forEach((element) => {
            if (element.data_emissione) {
              let tessereObj = {};
              tessereObj.id = element.id;
              tessereObj.id_persona = element.id_persona;
              tessereObj.cod_tessera = element.cod_tessera;
              tessereObj.id_societa = element.id_societa;
              tessereArray.value.push(tessereObj);
            }
          });
          isLoading.value = true;
          await preparaStampa(tessereArray.value, stagione.value).then(
            (res) => {
              if (res.status == 200) {
                item_id.value = res.data.item_id;
                isLoading.value = false;
                stampaTessereTutteBW(item_id.value);
              } else {
                isLoading.value = false;
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
            }
          );
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Tesserato",
        key: "nominativo_completo",
      },
      {
        name: "Numero Tessera",
        key: "cod_tessera",
      },
      /* {
        name: "Codice SNM",
        key: "codice_snm",
      }, */
      /* {
        name: "Tipo tessera",
        key: "tipo_tessera",
      }, */
      {
        name: "Categoria",
        key: "categoria",
      },
      {
        name: "Rank",
        key: "classifica_attuale",
        align: "center",
      },
      {
        name: "Scadenza certificato",
        key: "data_certificato",
        align: "center",
      },
      {
        name: "Data Emissione",
        key: "data_emissione",
        align: "center",
      },
      {
        name: "Data Scadenza",
        key: "data_scadenza",
        align: "center",
      },
      {
        name: "Data Stampa",
        key: "data_stampa",
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const setTutteLeStagioni = () => {
      store.commit("setTutteLeStagioniTesserati");
      emit("getTesseratiSocietaList");
    };

    const selectedPersona = ref(null);

    const loadingDetail = ref(false);
    const rank_list = ref(null);
    const getRankList = (id_persona, id_disciplina) => {
      loadingDetail.value = true;
      viewRankList(id_persona, id_disciplina).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          rank_list.value = res.data.results;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "dettaglio_classifica"
          );
        }
      });
    };

    const error = ref(false);
    const stagione = computed(() => store.getters.stagioneSelected);

    const attivita_sportiva_list = ref(null);
    const getAttivitaSportiva = (tessera) => {
      error.value = false;
      loadingDetail.value = true;
      viewAttivitaSportiva(tessera, stagione_selezionata.value).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          attivita_sportiva_list.value = res.data.subscriptions;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "dettaglio_attivita_sportiva"
          );
          error.value = true;
        }
      });
    };

    const selectedTessera = ref("");
    const selectedCrypt = ref("");

    const stagione_selezionata = ref(0);

    return {
      stagione_selezionata,
      tesserati_societa_list: computed(() =>
        store.getters.getStateFromName("resultstesserati_societa_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtesserati_societa_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordtesserati_societa_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statustesserati_societa_list")
      ),

      currentPage: computed(() => store.getters.currentPageTesseratoSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipTesseratoSoc),
      fetchRows: computed(() => store.getters.fetchRowsTesseratoSoc),
      sortColumn: computed(() => store.getters.sortColumnTesseratoSoc),
      sortOrder: computed(() => store.getters.sortOrderTesseratoSoc),
      setFetchRowsTesseratoSoc,
      setCurrentPageTesseratoSoc,
      setSortOrderColumn,
      tableHeader,
      tutteStagioniTesserati: computed(
        () => store.getters.tutteStagioniTesserati
      ),
      stampaTessera,
      stampaTessereArray,
      isLoading,
      setTutteLeStagioni,
      selectedPersona,
      getRankList,
      getAttivitaSportiva,
      rank_list,
      attivita_sportiva_list,
      loadingDetail,
      error,
      selectedTessera,
      selectedCrypt,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      isEnabled,
    };
  },
};
</script>

<style scoped></style>
