<template>
  <div>
    <FilterSocietyMembers
      @getTesseratiSocietaList="searchTesseratoSocList"
      @resetFilters="resetFilters"
      :resetted="resetted"
    />
    <TableSocietyMembers
      @getTesseratiSocietaList="getTesseratiSocietaList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";
import FilterSocietyMembers from "@/components/components-fit/tesserati/tesserati-societa/FiltriTesseratiSocieta.vue";
import TableSocietyMembers from "@/components/components-fit/tesserati/tesserati-societa/TableTesseratiSocieta.vue";

export default defineComponent({
  name: "society-members",
  components: {
    FilterSocietyMembers,
    TableSocietyMembers,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Tesserati Società", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipTesseratoSoc);
    const fetchRows = computed(() => store.getters.fetchRowsTesseratoSoc);
    const sortColumn = computed(() => store.getters.sortColumnTesseratoSoc);
    const sortOrder = computed(() => store.getters.sortOrderTesseratoSoc);

    const stagione = computed(() => store.getters.stagioneSelected);
    const id_Societa = computed(() => store.getters.idSocietaTesseratoSoc);
    const cognome = computed(() => store.getters.cognomeTesseratoSoc);
    const nome = computed(() => store.getters.nomeTesseratoSoc);
    const certificato = computed(() => store.getters.certificatoTesseratoSoc);
    const id_tipo_tessera = computed(
      () => store.getters.id_tipo_tesseraTesseratoSoc
    );
    const id_categoria = computed(() => store.getters.id_categoriaTesseratoSoc);
    const id_qualifica = computed(() => store.getters.idQualificaTesseratoSoc);
    const id_disciplina = computed(
      () => store.getters.id_disciplinaTesseratoSoc
    );
    const cod_tessera = computed(() => store.getters.codiceTesseraTesseratoSoc);
    const data_emissione_da = computed(
      () => store.getters.inizioTesseramentoTesseratoSoc
    );
    const data_emissione_a = computed(
      () => store.getters.fineTesseramentoTesseratoSoc
    );
    const id_categoria_eta = computed(
      () => store.getters.categoriaEtaTesseratoSoc
    );
    const id_comitato = computed(() => store.getters.comitatoTesseratoSoc);
    const id_regione = computed(() => store.getters.regioneTesseratoSoc);
    const id_provincia = computed(() => store.getters.provinciaTesseratoSoc);
    const id_comune = computed(() => store.getters.comuneTesseratoSoc);
    const tessere_estive = computed(
      () => store.getters.tesseraEstivaTesseratoSoc
    );
    const tessere_sat = computed(() => store.getters.tesseraSatTesseratoSoc);
    const da_approvare = computed(() => store.getters.daApprovareTesseratoSoc);
    /* const verificatoTesseratoSoc = computed(
      () => store.getters.verificatoTesseratoSoc
    ); */
    const stampate = computed(() => store.getters.tesseraStampataTesseratoSoc);
    const stampate_dal = computed(() => store.getters.stampateDaTesseratoSoc);
    const stampate_al = computed(() => store.getters.stampateAlTesseratoSoc);
    const tutteStagioniTesserati = computed(
      () => store.getters.tutteStagioniTesserati
    );

    const getTesseratiSocietaList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Societa: id_Societa.value,
          stagione: tutteStagioniTesserati.value ? 10000 : stagione.value,
          nome: trimInput(nome.value),
          cognome: trimInput(cognome.value),
          certificato: certificato.value,
          id_tipo_tessera: id_tipo_tessera.value,
          id_categoria: id_categoria.value,
          id_qualifica: id_qualifica.value,
          id_disciplina: id_disciplina.value,
          id_categoria_eta: id_categoria_eta.value,
          cod_tessera: trimInput(cod_tessera.value),
          data_emissione_da: data_emissione_da.value,
          data_emissione_a: data_emissione_a.value,
          id_comitato: id_comitato.value,
          id_regione: id_regione.value,
          id_provincia: id_provincia.value,
          id_comune: parseInt(id_comune.value ? id_comune.value : null),
          tessere_estive: tessere_estive.value,
          tessere_sat: tessere_sat.value,
          da_approvare: da_approvare.value,
          //verificato: verificatoTesseratoSoc.value,
          stampate: stampate.value,
          stampate_dal: stampate_dal.value,
          stampate_al: stampate_al.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.TESSERATI_SOCIETA_LIST,
        itemName: "tesserati_societa_list",
        version: 2,
      });
    };
    const resetted = ref(false);

    const resetFilters = () => {
      store.commit("resetFiltersTesseratoSoc");
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      getTesseratiSocietaList();
    };
    getTesseratiSocietaList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtesserati_societa_list")
    );

    const searchTesseratoSocList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTesseratoSoc");
      getTesseratiSocietaList();
    };

    return {
      getTesseratiSocietaList,
      resetFilters,
      resetted,
      searchTesseratoSocList,
    };
  },
});
</script>
