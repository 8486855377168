<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_esporta_tesserati"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Esportazione tesserati società</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="reset()"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row">
                <!--        <div class="col-sm-12 mb-4">
                  <label class="fw-bold">Tipo esportazione:</label>
                  <SelectInput
                    :options="tipoTessereLookup"
                    name="tipoTessereLookup"
                    placeholder="-"
                    :value="tipoTessere"
                    @changeSelect="setTipoTessere($event)"
                  />
                </div> -->
                <div class="col-sm-12">
                  <label class="fw-bold">Note esportazione:</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-label=""
                    v-model="note"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="modalEsporta"
            @click="reset()"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="esporta()"
          >
            Richiedi esportazione
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { esportaTesserati } from "@/requests/esportazioniRequests";

import { useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

//import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default {
  name: "modal-esporta-tesserati",
  components: { Loading },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const nomeTesseratoSoc = computed(() => store.getters.nomeTesseratoSoc);
    const cognomeTesseratoSoc = computed(
      () => store.getters.cognomeTesseratoSoc
    );
    const codiceTesseraTesseratoSoc = computed(
      () => store.getters.codiceTesseraTesseratoSoc
    );
    const societaDenominazioneTesseratoSoc = computed(
      () => store.getters.societaDenominazioneTesseratoSoc
    );
    const idSocietaTesseratoSoc = computed(
      () => store.getters.idSocietaTesseratoSoc
    );
    const comitatoTesseratoSoc = computed(
      () => store.getters.comitatoTesseratoSoc
    );
    const regioneTesseratoSoc = computed(
      () => store.getters.regioneTesseratoSoc
    );
    const provinciaTesseratoSoc = computed(
      () => store.getters.provinciaTesseratoSoc
    );
    const comuneTesseratoSoc = computed(() => store.getters.comuneTesseratoSoc);
    const id_tipo_tesseraTesseratoSoc = computed(
      () => store.getters.id_tipo_tesseraTesseratoSoc
    );
    const id_categoriaTesseratoSoc = computed(
      () => store.getters.id_categoriaTesseratoSoc
    );
    const id_disciplinaTesseratoSoc = computed(
      () => store.getters.id_disciplinaTesseratoSoc
    );
    const categoriaEtaTesseratoSoc = computed(
      () => store.getters.categoriaEtaTesseratoSoc
    );
    const inizioTesseramentoTesseratoSoc = computed(
      () => store.getters.inizioTesseramentoTesseratoSoc
    );
    const fineTesseramentoTesseratoSoc = computed(
      () => store.getters.fineTesseramentoTesseratoSoc
    );
    const tesseraEstivaTesseratoSoc = computed(
      () => store.getters.tesseraEstivaTesseratoSoc
    );
    const tesseraSatTesseratoSoc = computed(
      () => store.getters.tesseraSatTesseratoSoc
    );
    const daApprovareTesseratoSoc = computed(
      () => store.getters.daApprovareTesseratoSoc
    );
    /* const verificatoTesseratoSoc = computed(
      () => store.getters.verificatoTesseratoSoc
    ); */
    const tesseraStampataTesseratoSoc = computed(
      () => store.getters.tesseraStampataTesseratoSoc
    );
    const stampateDaTesseratoSoc = computed(
      () => store.getters.stampateDaTesseratoSoc
    );
    const stampateAlTesseratoSoc = computed(
      () => store.getters.stampateAlTesseratoSoc
    );
    const tutteStagioniTesserati = computed(
      () => store.getters.tutteStagioniTesserati
    );
    const stagioneTesserato = computed(() => store.getters.stagioneSelected);
    const stagione = ref(null);

    const id_provincia = computed(() => store.getters.provinciaTesseratoSoc);

    const note = ref(null);

    const isLoading = ref(false);

    const tipoTessere = ref(null);
    const setTipoTessere = (value) => {
      tipoTessere.value = value;
    };

    const esporta = () => {
      stagione.value = tutteStagioniTesserati.value
        ? 10000
        : stagioneTesserato.value;
      isLoading.value = true;
      esportaTesserati(
        idSocietaTesseratoSoc.value,
        stagione.value,
        nomeTesseratoSoc.value,
        cognomeTesseratoSoc.value,
        null,
        id_tipo_tesseraTesseratoSoc.value,
        id_categoriaTesseratoSoc.value,
        null,
        id_disciplinaTesseratoSoc.value,
        categoriaEtaTesseratoSoc.value,
        codiceTesseraTesseratoSoc.value,
        inizioTesseramentoTesseratoSoc.value,
        fineTesseramentoTesseratoSoc.value,
        comitatoTesseratoSoc.value,
        regioneTesseratoSoc.value,
        provinciaTesseratoSoc.value,
        comuneTesseratoSoc.value
          ? comuneTesseratoSoc.value.value
          : comuneTesseratoSoc.value,
        tesseraEstivaTesseratoSoc.value,
        tesseraSatTesseratoSoc.value,
        daApprovareTesseratoSoc.value,
        /* verificatoTesseratoSoc.value, */
        tesseraStampataTesseratoSoc.value,
        stampateDaTesseratoSoc.value,
        stampateAlTesseratoSoc.value,
        note.value
      ).then((res) => {
        isLoading.value = false;

        if (res.status == 200) {
          Swal.fire({
            html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Lista Esportazioni",
            cancelButtonText: "Chiudi",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-danger",
            },
          }).then((input) => {
            document.getElementById("modalEsporta").click();
            if (input.isConfirmed) {
              // window.location.href = `/esportazioni`;
              router.push(`/esportazioni`);
              return;
            }
          });
          note.value = null;
          tipoTessere.value = null;
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
      /*   if (tipoTessere.value == 1) {
      } else if (tipoTessere.value == 2) {
        isLoading.value = true;
        esportaTesseratiGold(stagione.value, note.value).then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            Swal.fire({
              html: "L'esportazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato dell'esportazione ed effettuare il download del documento all'interno della pagina 'Esportazioni'",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Lista Esportazioni",
              cancelButtonText: "Chiudi",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then((input) => {
              document.getElementById("modalEsporta").click();
              if (input.isConfirmed) {
                // window.location.href = `/esportazioni`;
                router.push(`/esportazioni`);
                return;
              }
            });
            note.value = null;
            tipoTessere.value = null;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        });
      } else {
        alertFailed("Selezionare il tipo di esportazione.");
      } */
    };

    const reset = () => {
      note.value = null;
      tipoTessere.value = null;
    };

    return {
      nomeTesseratoSoc,
      cognomeTesseratoSoc,
      codiceTesseraTesseratoSoc,
      idSocietaTesseratoSoc,
      societaDenominazioneTesseratoSoc,
      comitatoTesseratoSoc,
      regioneTesseratoSoc,
      provinciaTesseratoSoc,
      comuneTesseratoSoc,
      id_tipo_tesseraTesseratoSoc,
      id_categoriaTesseratoSoc,
      id_disciplinaTesseratoSoc,
      categoriaEtaTesseratoSoc,
      inizioTesseramentoTesseratoSoc,
      fineTesseramentoTesseratoSoc,
      tesseraEstivaTesseratoSoc,
      tesseraSatTesseratoSoc,
      daApprovareTesseratoSoc,
      /*  verificatoTesseratoSoc, */
      tesseraStampataTesseratoSoc,
      stampateDaTesseratoSoc,
      stampateAlTesseratoSoc,
      stagione,
      stagioneTesserato,
      tutteStagioniTesserati,
      id_provincia,
      note,
      esporta,
      isLoading,
      tipoTessere,
      tipoTessereLookup: [
        { id: 1, label: "Lista filtrata" },
        { id: 2, label: "Tesserati GOLD" },
      ],
      setTipoTessere,
      reset,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
