<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getTesseratiSocietaList')"
        >
          <div class="row">
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Cognome"
                aria-label=""
                :value="cognomeTesseratoSoc"
                @input="setCognomeTesseratoSoc"
              />
            </div>
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Nome"
                aria-label=""
                :value="nomeTesseratoSoc"
                @input="setNomeTesseratoSoc"
              />
            </div>

            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Cod. tessera"
                aria-label=""
                :value="codiceTesseraTesseratoSoc"
                @input="setCodiceTesseraSoc"
              />
            </div>
            <div class="col-sm-3">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="denominazione_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="societaDenominazioneTesseratoSoc"
                  autocomplete="off"
                />
                <svg
                  @click="setIdSocietaTesseratoSoc({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocieta();
                  setIdSocietaTesseratoSoc($event);
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
          </div>
          <div class="text-center mt-5">
            <span
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFilterSociety"
              aria-expanded="false"
              aria-controls="collapseExample"
              class="badge rounded-pill bg-light text-gray-600"
              @click="isActive = !isActive"
              >Filtri avanzati
              <i
                :class="{
                  'fas fa-angle-up fs-6 text-gray-600': isActive,
                  'fas fa-angle-down fs-6 text-gray-600': !isActive,
                }"
              >
              </i
            ></span>
            <button
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getTesseratiSocietaList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              v-if="!isActive"
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                isRegioneSelected = false;
                isProvinciaSelected = false;
                resetProvince();
                comuneString = '';
                spliceCategoria();
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>

          <div class="collapse" id="collapseFilterSociety">
            <div class="bg-light rounded-3">
              <div class="row mt-4 pt-4 ps-2 pe-2">
                <div class="col-sm-3 filter">
                  <SelectInput
                    :options="comitati"
                    name="comitati"
                    placeholder="Comitato"
                    :value="comitatoTesseratoSoc"
                    @changeSelect="setComitatoTesseratoSoc($event)"
                  />
                </div>
                <div class="col-sm-3 filter">
                  <select
                    class="form-select"
                    aria-label=""
                    :value="regioneTesseratoSoc"
                    @change="setRegioneTesseratoSoc($event)"
                  >
                    <option selected value="">Regione</option>
                    <option
                      v-for="(regione, index) in istat_regioni_province"
                      :key="index"
                      :value="regione.codR"
                      :id="regione.codR"
                    >
                      {{ regione.nomeR }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-3 filter">
                  <SelectInput
                    :options="province"
                    name="province"
                    placeholder="Provincia"
                    :value="provinciaTesseratoSoc"
                    @changeSelect="
                      setProvinciaTesseratoSoc($event);
                      changeIsProvinciaSelected($event);
                    "
                    :disabled="!regioneTesseratoSoc"
                  />
                </div>
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Comune"
                    aria-label=""
                    @input="
                      spliceComuniIstat();
                      setComuni();
                    "
                    v-model="comuneString"
                    @keyup.delete="spliceComuniIstat"
                    :disabled="isProvinciaSelected ? false : true"
                    autocomplete="off"
                  />
                  <ListComuni
                    v-if="comuniIstat"
                    :list="comuniIstat"
                    @hideList="spliceComuniIstat"
                    @selectedElement="
                      comuneString = $event.name;
                      setComuneTesseratoSoc($event);
                      spliceComuniIstat();
                    "
                  ></ListComuni>
                </div>
              </div>
              <div class="row ps-2 pe-2">
                <div class="col-sm-3">
                  <div class="pt-4">
                    <SelectInput
                      :options="tesseramento_tipo_tessere"
                      name="tesseramento_tipo_tessere"
                      placeholder="Tipo tessera"
                      :value="id_tipo_tesseraTesseratoSoc"
                      @changeSelect="
                        setTipoTesseraSoc($event);
                        getCategoriaTesSoc(id_tipo_tesseraTesseratoSoc);
                        setCategoriaTesseraSoc(null);
                        spliceCategoria();
                      "
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Multiselect
                      v-model="selectedCategoria"
                      :options="tesseramento_tipologie_multi"
                      @change="setSelectedCategoria($event)"
                      mode="tags"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="false"
                      placeholder="Categoria"
                      :maxHeight="150"
                      :disabled="!id_tipo_tesseraTesseratoSoc"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Multiselect
                      v-model="selectedDiscipline"
                      :options="discipline_multi"
                      @change="setSelectedDiscipline($event)"
                      mode="tags"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="false"
                      placeholder="Settore"
                      :maxHeight="150"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Multiselect
                      v-model="selectedCategoriaEta"
                      :options="tesseramento_categorie_eta_multi"
                      @change="setSelectedCategoriaEta($event)"
                      mode="tags"
                      :close-on-select="false"
                      :searchable="true"
                      :create-option="false"
                      placeholder="Categoria d'età"
                      :maxHeight="150"
                    />
                  </div>
                </div>
              </div>
              <div class="row ps-2 pe-2">
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Datepicker
                      v-model="inizioTesseramentoTesseratoSoc"
                      placeholder="Inizio tesseramento"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      @update:modelValue="setInizioTesseramentoSoc($event)"
                      autoApply
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Datepicker
                      v-model="fineTesseramentoTesseratoSoc"
                      placeholder="Fine tesseramento"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      @update:modelValue="setFineTesseramentoSoc($event)"
                      autoApply
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <SelectInput
                      :options="trueFalseLookup"
                      name="trueFalseLookup"
                      placeholder="Tessere estive"
                      :value="tesseraEstivaTesseratoSoc"
                      @changeSelect="setTesseraEstivaTesseratoSoc($event)"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <SelectInput
                      :options="trueFalseLookup"
                      name="trueFalseLookup"
                      placeholder="Tessere SAT"
                      :value="tesseraSatTesseratoSoc"
                      @changeSelect="setTesseraSatTesseratoSoc($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="row ps-2 pe-2 pb-4">
                <div class="col-sm-3">
                  <div class="pt-4">
                    <SelectInput
                      :options="trueFalseLookup"
                      name="trueFalseLookup"
                      placeholder="Tessere stampate"
                      :value="tesseraStampataTesseratoSoc"
                      @changeSelect="setTesseraStampataTesseratoSoc($event)"
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Datepicker
                      v-model="stampateDaTesseratoSoc"
                      placeholder="Stampate dal"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      @update:modelValue="setStampateDaTesseratoSoc($event)"
                      autoApply
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <Datepicker
                      v-model="stampateAlTesseratoSoc"
                      placeholder="Stampate al"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      @update:modelValue="setStampateAlTesseratoSoc($event)"
                      autoApply
                    />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="pt-4">
                    <SelectInput
                      :options="trueFalseLookup"
                      name="trueFalseLookup"
                      placeholder="Approvato"
                      :value="daApprovareTesseratoSoc"
                      @changeSelect="setdaApprovareTesseratoSoc($event)"
                    />
                  </div>
                </div>
                <!-- <div class="col-sm-3">
                  <div class="pt-4">
                    <SelectInput
                      :options="trueFalseLookup"
                      name="trueFalseLookup"
                      placeholder="Approvato"
                      :value="verificatoTesseratoSoc"
                      @changeSelect="setVerificatoTesseratoSoc($event)"
                    />
                  </div>
                </div> -->
              </div>
            </div>
            <div class="text-center mt-4">
              <button
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="$emit('getTesseratiSocietaList')"
                :disabled="!loaded"
              >
                Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
              </button>
              <span
                v-if="isActive"
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="badge rounded-pill bg-light text-gray-600 ms-4"
                @click="
                  $emit('resetFilters');
                  isRegioneSelected = false;
                  isProvinciaSelected = false;
                  resetProvince();
                  comuneString = '';
                  spliceCategoria();
                "
              >
                <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
              ></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ListSocieta from "../../utility/ListSocieta.vue";
import ListComuni from "../../utility/ListComuni.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { useFormatDateYMD, format } from "@/composables/formatDate";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "filtri-tesserati-societa",
  emits: ["resetFilters", "getTesseratiSocietaList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    ListComuni,
    ListSocieta,
    SelectInput,
    Datepicker,
    Multiselect,
  },
  setup(props, { emit }) {
    const isActive = ref(false);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const istat_regioni_province = computed(() =>
      store.getters.getStateFromName("istat_regioni_province")
    );
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tesseramento_tipo_tessere = computed(() =>
      store.getters.getStateFromName("tesseramento_tipo_tessere")
    );
    const tesseramento_tipologie = computed(() =>
      store.getters.getStateFromName("tesseramento_tipologie")
    );
    const tesseramento_categorie_eta = computed(() =>
      store.getters.getStateFromName("tesseramento_categorie_eta")
    );

    const keys = ref("c|irp|d|ttt|tce");
    store.dispatch("setStoreData", {
      keys: { keys: keys.value },
      apiLink: globalApi.COMBOLIST_GET,
    });

    const nomeTesseratoSoc = computed(() => store.getters.nomeTesseratoSoc);
    const setNomeTesseratoSoc = (e) => {
      const string = e.target.value;
      store.commit("setNomeTesseratoSoc", string);
    };

    const cognomeTesseratoSoc = computed(
      () => store.getters.cognomeTesseratoSoc
    );
    const setCognomeTesseratoSoc = (e) => {
      const string = e.target.value;
      store.commit("setCognomeTesseratoSoc", string);
    };

    const codiceTesseraTesseratoSoc = computed(
      () => store.getters.codiceTesseraTesseratoSoc
    );
    const setCodiceTesseraSoc = (e) => {
      const string = e.target.value;
      store.commit("setCodiceTesseraSoc", string);
    };

    const societaDenominazioneTesseratoSoc = computed(
      () => store.getters.societaDenominazioneTesseratoSoc
    );
    const idSocietaTesseratoSoc = computed(
      () => store.getters.idSocietaTesseratoSoc
    );
    const setIdSocietaTesseratoSoc = ({ value, name }) => {
      store.commit("setIdSocietaTesseratoSoc", value);
      store.commit("setSocietaDenominazioneTesseratoSoc", name);
    };

    const comitatoTesseratoSoc = computed(
      () => store.getters.comitatoTesseratoSoc
    );
    const setComitatoTesseratoSoc = (event) => {
      store.commit("setComitatoTesseratoSoc", event);
    };

    const province = ref([]);

    const regioneTesseratoSoc = computed(
      () => store.getters.regioneTesseratoSoc
    );
    const setRegioneTesseratoSoc = (event) => {
      const selectedId =
        event.target.selectedOptions[0].id != ""
          ? parseInt(event.target.selectedOptions[0].id)
          : null;
      store.commit("setRegioneTesseratoSoc", selectedId);
      province.value.splice(0, province.value.length);
      istat_regioni_province.value.forEach((element) => {
        if (element.codR === selectedId) {
          element.Province.forEach((prov) => {
            province.value.push(prov);
          });
        }
      });
    };

    const provinciaTesseratoSoc = computed(
      () => store.getters.provinciaTesseratoSoc
    );
    const setProvinciaTesseratoSoc = (event) => {
      store.commit("setProvinciaTesseratoSoc", event);
    };

    const resetProvince = () => {
      province.value.splice(0, province.value.length);
    };

    const comuneTesseratoSoc = computed(() => store.getters.comuneTesseratoSoc);
    const setComuneTesseratoSoc = (event) => {
      store.commit("setComuneTesseratoSoc", event);
    };

    //MULTISELECT
    const selectedCategoria = computed(() => store.getters.selected_categoria);
    const selectedDiscipline = computed(
      () => store.getters.selected_discipline
    );
    const selectedCategoriaEta = computed(
      () => store.getters.selected_categoria_eta
    );
    const tesseramento_tipologie_multi = computed(
      () => store.getters.tesseramento_tipologie_multi
    );
    const discipline_multi = computed(() => store.getters.discipline_multi);
    const tesseramento_categorie_eta_multi = computed(
      () => store.getters.tesseramento_categorie_eta_multi
    );

    const id_tipo_tesseraTesseratoSoc = computed(
      () => store.getters.id_tipo_tesseraTesseratoSoc
    );
    const setTipoTesseraSoc = (event) => {
      store.commit("setTipoTesseraSoc", event);
    };

    const getCategoriaTesSoc = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_tipo_tessera: id },
        apiLink: globalApi.TESSERAMENTO_TIPOLOGIE,
      });
    };

    const id_categoriaTesseratoSoc = computed(
      () => store.getters.id_categoriaTesseratoSoc
    );
    const setCategoriaTesseraSoc = (event) => {
      store.commit("setCategoriaTesseraSoc", event);
    };

    const spliceCategoria = () => {
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      id_categoriaTesseratoSoc.value = null;
      store.commit("settesseramento_tipologie_multi", []);
      store.commit("setselected_categoria", []);
      tipologie_for_store.value = [];
    };

    const tipologie_for_store = ref([]);
    watch(tesseramento_tipologie, () => {
      tesseramento_tipologie.value.forEach((element) => {
        let tag = {
          value: element.id,
          label: element.label,
        };
        tipologie_for_store.value.push(tag);
      });
      store.commit(
        "settesseramento_tipologie_multi",
        tipologie_for_store.value
      );
    });

    const ids_categorie_string = ref("");
    const setSelectedCategoria = (event) => {
      store.commit("setselected_categoria", event);
      ids_categorie_string.value = "";
      selectedCategoria.value.forEach((element) => {
        ids_categorie_string.value = ids_categorie_string.value + element + ",";
      });
      setCategoriaTesseraSoc(ids_categorie_string.value);
    };

    const id_disciplinaTesseratoSoc = computed(
      () => store.getters.id_disciplinaTesseratoSoc
    );
    const setSettoreSoc = (event) => {
      store.commit("setSettoreSoc", event);
    };

    const discipline_for_store = ref([]);
    watch(discipline, () => {
      discipline.value.forEach((element) => {
        let tag = {
          value: element.id,
          label: element.nome,
        };
        discipline_for_store.value.push(tag);
      });
      store.commit("setdiscipline_multi", discipline_for_store.value);
    });

    const ids_discipline_string = ref("");
    const setSelectedDiscipline = (event) => {
      store.commit("setselected_discipline", event);
      ids_discipline_string.value = "";
      selectedDiscipline.value.forEach((element) => {
        ids_discipline_string.value =
          ids_discipline_string.value + element + ",";
      });
      setSettoreSoc(ids_discipline_string.value);
    };

    const categoriaEtaTesseratoSoc = computed(
      () => store.getters.categoriaEtaTesseratoSoc
    );
    const setCategoriaEtaTesseratoSoc = (event) => {
      store.commit("setCategoriaEtaTesseratoSoc", event);
    };

    const categorie_eta_for_store = ref([]);
    watch(tesseramento_categorie_eta, () => {
      tesseramento_categorie_eta.value.forEach((element) => {
        let tag = {
          value: element.id,
          label: element.label,
        };
        categorie_eta_for_store.value.push(tag);
      });
      store.commit(
        "settesseramento_categorie_eta_multi",
        categorie_eta_for_store.value
      );
    });

    const ids_categorie_eta_string = ref("");
    const setSelectedCategoriaEta = (event) => {
      store.commit("setselected_categoria_eta", event);
      ids_categorie_eta_string.value = "";
      selectedCategoriaEta.value.forEach((element) => {
        ids_categorie_eta_string.value =
          ids_categorie_eta_string.value + element + ",";
      });
      setCategoriaEtaTesseratoSoc(ids_categorie_eta_string.value);
    };

    const inizioTesseramentoTesseratoSoc = computed(
      () => store.getters.inizioTesseramentoTesseratoSoc
    );
    const setInizioTesseramentoSoc = (event) => {
      store.commit("setInizioTesseramentoSoc", event);
    };

    const fineTesseramentoTesseratoSoc = computed(
      () => store.getters.fineTesseramentoTesseratoSoc
    );
    const setFineTesseramentoSoc = (event) => {
      store.commit("setFineTesseramentoSoc", event);
    };

    const tesseraEstivaTesseratoSoc = computed(
      () => store.getters.tesseraEstivaTesseratoSoc
    );
    const setTesseraEstivaTesseratoSoc = (event) => {
      store.commit("setTesseraEstivaTesseratoSoc", event);
    };

    const tesseraSatTesseratoSoc = computed(
      () => store.getters.tesseraSatTesseratoSoc
    );
    const setTesseraSatTesseratoSoc = (event) => {
      store.commit("setTesseraSatTesseratoSoc", event);
    };

    const daApprovareTesseratoSoc = computed(
      () => store.getters.daApprovareTesseratoSoc
    );
    const setdaApprovareTesseratoSoc = (event) => {
      store.commit("setdaApprovareTesseratoSoc", event);
    };
    /*  const verificatoTesseratoSoc = computed(
      () => store.getters.verificatoTesseratoSoc
    );
    const setVerificatoTesseratoSoc = (event) => {
      store.commit("setVerificatoTesseratoSoc", event);
    }; */

    const tesseraStampataTesseratoSoc = computed(
      () => store.getters.tesseraStampataTesseratoSoc
    );
    const setTesseraStampataTesseratoSoc = (event) => {
      store.commit("setTesseraStampataTesseratoSoc", event);
    };

    const stampateDaTesseratoSoc = computed(
      () => store.getters.stampateDaTesseratoSoc
    );
    const setStampateDaTesseratoSoc = (event) => {
      store.commit("setStampateDaTesseratoSoc", event);
    };

    const stampateAlTesseratoSoc = computed(
      () => store.getters.stampateAlTesseratoSoc
    );
    const setStampateAlTesseratoSoc = (event) => {
      store.commit("setStampateAlTesseratoSoc", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getTesseratiSocietaList");
    });

    const getSocieta = (string) => {
      store.commit("setSocietaDenominazioneTesseratoSoc", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const id_provincia = computed(() => store.getters.provinciaTesseratoSoc);
    const comuneString = ref("");
    const isProvinciaSelected = ref(false);

    const changeIsProvinciaSelected = (e) => {
      comuneString.value = "";
      if (e !== null) {
        isProvinciaSelected.value = true;
      } else {
        isProvinciaSelected.value = false;
      }
    };

    const setComuni = () => {
      if (comuneString.value.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString.value,
            codp: id_provincia.value,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };
    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          resetProvince();
          comuneString.value = "";
        }
      }
    );

    const inputFocus = ref(null);

    return {
      inputFocus,
      isActive,
      getSocieta,
      resetSocieta,
      resetProvince,
      nomeTesseratoSoc,
      setNomeTesseratoSoc,
      cognomeTesseratoSoc,
      setCognomeTesseratoSoc,
      codiceTesseraTesseratoSoc,
      setCodiceTesseraSoc,
      idSocietaTesseratoSoc,
      setIdSocietaTesseratoSoc,
      societa: computed(() => store.getters.societaNomeCodAff),
      societaDenominazioneTesseratoSoc,
      comitatoTesseratoSoc,
      setComitatoTesseratoSoc,
      regioneTesseratoSoc,
      setRegioneTesseratoSoc,
      provinciaTesseratoSoc,
      setProvinciaTesseratoSoc,
      comuneTesseratoSoc,
      setComuneTesseratoSoc,
      id_tipo_tesseraTesseratoSoc,
      setTipoTesseraSoc,
      id_categoriaTesseratoSoc,
      setCategoriaTesseraSoc,
      id_disciplinaTesseratoSoc,
      setSettoreSoc,
      categoriaEtaTesseratoSoc,
      setCategoriaEtaTesseratoSoc,
      inizioTesseramentoTesseratoSoc,
      setInizioTesseramentoSoc,
      fineTesseramentoTesseratoSoc,
      setFineTesseramentoSoc,
      tesseraEstivaTesseratoSoc,
      setTesseraEstivaTesseratoSoc,
      tesseraSatTesseratoSoc,
      setTesseraSatTesseratoSoc,
      daApprovareTesseratoSoc,
      setdaApprovareTesseratoSoc,
      /* verificatoTesseratoSoc,
      setVerificatoTesseratoSoc, */
      tesseraStampataTesseratoSoc,
      setTesseraStampataTesseratoSoc,
      stampateDaTesseratoSoc,
      setStampateDaTesseratoSoc,
      stampateAlTesseratoSoc,
      setStampateAlTesseratoSoc,
      stagione: computed(() => store.getters.stagioneSelected),
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      comitati,
      discipline,
      tesseramento_tipo_tessere,
      tesseramento_tipologie,
      tesseramento_categorie_eta,
      istat_regioni_province,
      province,
      changeIsProvinciaSelected,
      isProvinciaSelected,
      setComuni,
      spliceComuniIstat,
      spliceCategoria,
      comuneString,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      getCategoriaTesSoc,
      useFormatDateYMD,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtesserati_societa_list")
      ),
      selectedCategoria,
      tesseramento_tipologie_multi,
      selectedDiscipline,
      discipline_multi,
      tesseramento_categorie_eta_multi,
      selectedCategoriaEta,
      setSelectedCategoria,
      setSelectedDiscipline,
      setSelectedCategoriaEta,
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
