<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="dettaglio_classifica"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Storico classifica - {{ nominativo_persona }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row pb-4"></div>
              <div class="mx-auto my-auto text-center" v-if="loadingDetail">
                <div class="spinner-border text-gray-600" role="status"></div>
                <span class="text-gray-600 ps-3 fs-2">Loading</span>
              </div>
              <div
                v-else-if="
                  rank_list == undefined || rank_list == null || rank_list == ''
                "
                class="m-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
              >
                <span class="text-gray-700 ps-3 fs-4">
                  Attenzione! Nessun risultato.
                </span>
              </div>
              <div v-else class="table-responsive card shadow">
                <table class="table table-striped gy-3 gs-7 align-middle">
                  <thead>
                    <tr
                      class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                    >
                      <th scope="col">Stagione</th>
                      <th scope="col">Disciplina</th>
                      <th scope="col">Fascia</th>
                      <th scope="col">Gruppo</th>
                      <th scope="col" class="tab-long text-center">
                        Data decorrenza
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(corso, index) in rank_list" :key="index">
                      <tr>
                        <td>{{ corso.stagione }}</td>
                        <td>{{ corso.disciplina_view }}</td>
                        <td v-if="corso.categoria_view">
                          {{ corso.categoria_view }}
                        </td>
                        <td v-else>-</td>
                        <td>{{ corso.gruppo }}</td>
                        <td class="tab-long text-center">
                          {{ corso.data_inserimento }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "modal-dettaglio-classifica",
  props: {
    rank_list: {
      type: Array,
      required: true,
    },
    nominativo_persona: {
      type: String,
      required: true,
    },
    loadingDetail: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
        sortable: false,
      },
      {
        name: "Disciplina",
        key: "disciplina_view",
        sortable: false,
      },
      {
        name: "Gruppo",
        key: "gruppo",
        sortable: false,
      },
      {
        name: "Data di inserimento",
        key: "data_inserimento",
        sortable: false,
      },
      {
        name: "id",
        key: "id",
        sortable: false,
      },
    ]);

    return {
      tableHeader,
      getFormatDate,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
  max-width: 32%;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
